// wtf

/* global google:true */

// https://biati-digital.github.io/glightbox/#examples
// https://michaelmovsesov.com/articles/jekyll-es6-workflow
$(document).ready(function () {
	// highlight current page in menu
	// Activates the Carousel
	var $carousel = $('.carousel');
	if ($carousel.length > 0) {
		$('.carousel').carousel({
			interval: 5000
		});
	}
	var $menu = $('.menu');
	var $toggleButton = $('.toggle');
	var $navBar = $('.agd-nav-bar');

	$toggleButton.on('click', function (event) {
		$navBar.toggleClass('mobile-menu-open');
		event.stopImmediatePropagation();
		event.preventDefault();
	});

	$navBar.on('click', function (event) {
		$navBar.find('.submenu-active').removeClass('submenu-active');
	});

	$('.menu .has-submenu').on('click keypress', function (event) {
		var $clickedParent = $(this);
		if ($clickedParent.hasClass('submenu-active')) {
			$clickedParent.removeClass('submenu-active');
		} else {
			$menu.find('.submenu-active').removeClass('submenu-active');
			$clickedParent.addClass('submenu-active');
		}
		event.stopImmediatePropagation();
	});
	setupHeaderScrolly();

	var $gallery = $('.gallery');
	if ($gallery.length < 1) {
		return;
	}
	var lightbox = GLightbox({
		selector: '.galleryItem'
	});
});

function setupHeaderScrolly() {
	var $header = $('.agd-nav-bar');

	window.onscroll = function () {
		console.log(new Date(), 'scrolled');
		scrollFunction();
	};

	function scrollFunction() {
		//el.classList.toggle(className);
		var setSmallHeader = document.body.scrollTop > 240 || document.documentElement.scrollTop > 240;
		$header.toggleClass('small-header', setSmallHeader);

		// if (document.body.scrollTop > 240 || document.documentElement.scrollTop > 240) {
		// 	$header.addClass('small-header');
		// } else {
		// 	$header.removeClass('small-header');
		// }
	}
}

/*
function sendFeedback($form) {
var post_data = {
userName: $form.find('#input1').val(),
userEmail: $form.find('#input2').val(),
userPhone: $form.find('#input3').val(),
userMessage: $form.find('#input4').val(),
save: 'contact',
};
$.post('email_me.php', post_data, function () {
// success !
$('#feedback').addClass('alert-info').html('Thanks for your details, we will be in touch shortly.').slideDown();
}).fail(function (err) {
$('#feedback')
.addClass('alert-danger')
.html('Sorry, but there is a problem with the information provided<br><br>' + err.statusText + '<br>')
.slideDown();
});
}
*/

function setupMap(mapID, options) {
	var myLatlng = new google.maps.LatLng(51.8031665, -1.5105039);
	var myOptions = {
		zoom: 12,
		center: myLatlng,
		disableDefaultUI: true,
		panControl: true,
		zoomControl: true,
		zoomControlOptions: {
			style: google.maps.ZoomControlStyle.DEFAULT
		},
		mapTypeControl: false,
		streetViewControl: false,
		mapTypeId: google.maps.MapTypeId.ROADMAP
	};
	if (!!options) {
		$.extend(myOptions, options);
	}
	//
	//var map = new google.maps.Map(document.getElementById("myMap"), myOptions);
	var map = new google.maps.Map($('#' + mapID)[0], myOptions);
	var marker = new google.maps.Marker({
		position: myLatlng,
		map: map,
		maxWidth: 250,
		height: 500,
		title: 'AGD Dorflo Ltd - OX29 9TJ'
	});
	var infowindow = new google.maps.InfoWindow({
		content: 'AGD Dorflo Ltd<br>Unit 24a.<br>Crawley Mill Industrial Estate.<br>OX29 9TJ'
	});
	google.maps.event.addListener(marker, 'click', function () {
		infowindow.open(map, marker);
	});
}
